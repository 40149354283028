import React, { Fragment } from 'react'
import styled, { keyframes } from 'styled-components'
import TinyColor from '@ctrl/tinycolor'
import MediaQuery from 'react-responsive'
import MedusaImage from './MedusaImage'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'
import Logo from './Logo'
import { ThemeConsumer } from '../../state'
import config from '../../config'

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.header`
  width: 100%;
  position: relative;
`

const TopGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 7rem;
  top: 0;
  background: linear-gradient(to bottom, ${({ theme }) => new TinyColor(theme.colors.primary).setAlpha(1).toRgbString()} 0%, ${({ theme }) => new TinyColor(theme.colors.primary).setAlpha(0).toRgbString()} 100%);
`

const BottomGradient = styled.div`
  position: absolute;
  width: 100%;
  height: 7rem;
  bottom: 0;
  background: linear-gradient(to top, ${({ theme }) => new TinyColor(theme.colors.primary).setAlpha(1).toRgbString()} 0%, ${({ theme }) => new TinyColor(theme.colors.primary).setAlpha(0).toRgbString()} 100%);
`

const HeaderSpace = styled.div`
  height: 40rem;
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.contentWidth};
  margin: 0 auto;
  position: relative;
  z-index: 2
`

const SubContainer = styled.div`
  overflow: hidden;
  width: 100%;
  position: absolute;
  top: 0;
`

const Wrapper = styled.div`
  width: 100%;
  height: 55.8rem;
  display: flex;
  align-items: center;
  justify-content:space-around;
`

const MedusaImageContainer = styled.div`
  position: absolute;
  top: 50%;
  width: 30%;
  display: block;
  transform: translateY(-50%);
`

const HeaderImage = styled.img`
  position: absolute;
  margin: 0;
  width: 70%;
  opacity: 0.35;
  animation-name: ${spin};
  animation-duration: 500s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

const MainTitleLogo = styled.div`
  position: absolute;
  top: 5rem;
  z-index: 2;
`

export default () => (
  <ThemeConsumer>
    {({ theme }) => (
      <Fragment>
        <MediaQuery query={`(max-width: ${config.mobileHeaderBreakPoint - 1}px)`}>
          <MobileNavigation />
        </MediaQuery>
        <Container>
          <HeaderSpace>
            <MediaQuery query={`(min-width: ${config.mobileHeaderBreakPoint}px)`}>
              <DesktopNavigation />
            </MediaQuery>
          </HeaderSpace>
          <SubContainer>
            <Wrapper>
              <HeaderImage src={theme.images.headerBackground} alt='Cardano header' />
              <MedusaImageContainer>
                <MedusaImage />
              </MedusaImageContainer>
              <TopGradient />
              <BottomGradient />
              <MainTitleLogo>
                <Logo />
              </MainTitleLogo>
            </Wrapper>
          </SubContainer>
        </Container>
      </Fragment>
    )}
  </ThemeConsumer>
)
