// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/build/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ada-token-js": () => import("/opt/build/repo/src/pages/ada-token.js" /* webpackChunkName: "component---src-pages-ada-token-js" */),
  "component---src-pages-community-js": () => import("/opt/build/repo/src/pages/community.js" /* webpackChunkName: "component---src-pages-community-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-the-project-overview-js": () => import("/opt/build/repo/src/pages/the-project/overview.js" /* webpackChunkName: "component---src-pages-the-project-overview-js" */),
  "component---src-pages-the-project-the-algorithm-js": () => import("/opt/build/repo/src/pages/the-project/the-algorithm.js" /* webpackChunkName: "component---src-pages-the-project-the-algorithm-js" */),
  "component---src-pages-the-project-the-philosophy-js": () => import("/opt/build/repo/src/pages/the-project/the-philosophy.js" /* webpackChunkName: "component---src-pages-the-project-the-philosophy-js" */),
  "component---src-pages-the-project-the-sourcecode-js": () => import("/opt/build/repo/src/pages/the-project/the-sourcecode.js" /* webpackChunkName: "component---src-pages-the-project-the-sourcecode-js" */),
  "component---src-pages-transparency-js": () => import("/opt/build/repo/src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */),
  "component---src-pages-updates-js": () => import("/opt/build/repo/src/pages/updates.js" /* webpackChunkName: "component---src-pages-updates-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

