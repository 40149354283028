import App from './src/App'
import Root from './src/Root'
import { setPageLoading } from './src/state/PageLoader'

export const wrapPageElement = App
export const wrapRootElement = Root

export const onInitialClientRender = () => {
  setPageLoading(false)
}

export const onRouteUpdate = () => {
  setPageLoading(false)
}

export const onRouteUpdateDelayed = () => {
  setPageLoading(true)
}
