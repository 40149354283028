import React, { useState, Fragment } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { ThemeConsumer, LanguageConsumer } from '../../state'
import Query from './Query'
import { getURIPathWithoutLang } from '../../helpers/url'

const Container = styled.div`
  position: fixed;
  text-align: center;
  width: 100%;
  z-index: 4;
  left: 0;
`

const BurgerMenuLink = styled.a`
  position:absolute;
  z-index:5;
  top:1rem;
  right:1rem;
  height:3.2rem;
`

const BurgerIcon = styled.img`
  margin:0;
`

const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 6rem;
  height: 100vh;
  width: 100%;
  position:relative;
  transform: translate(0, 0);
  background-color: ${({ theme }) => theme.colors.secondary};
  z-index:2;
  width:100%;
  padding:0 1rem;
  position: absolute;
  overflow: scroll;

  > ul {
    margin-top: 5rem;
    list-style: none;

    > li {
      margin: 2rem 0;

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    a {
      color: ${({ theme }) => theme.colors.interactive};
      text-decoration: none;
      text-transform: uppercase;
      flex: 1;
      font-weight: 600;
      font-size: 2rem;
      letter-spacing: 0.1em;
      transition: color 0.2s ease-in-out;

      &:hover,
      &.active,
      &:focus {
        color: ${({ theme }) => theme.colors.text}
      }
    }
  }

  &.mobileNav-enter {
    transition: transform 0.5s ease-in-out;
    transform: translate(0, -100vh);
  }

  &.mobileNav-leave {
    transition: transform 0.35s ease-in;
    transform: translate(0, 0);
  }

  &.mobileNav-enter-active {
    transform: translate(0, 0);
  }

  &.mobileNav-leave-active {
    transform: translate(0, -100vh);
  }
`

const DropdownArrow = styled.img`
  width: 1.6rem;
  margin: 0;
  vertical-align: inherit;
`

const Dropdown = styled.ul`
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: left;
  min-width: 20rem;
  list-style: none;
  margin: 0 0 2rem;
  opacity: 1;
  border-left: 0.1rem solid ${({ theme }) => theme.colors.accent};
  padding: 0 2rem;
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    height: 2rem;
  }

  &.dropdown-enter {
    max-height: 0;
    opacity:0;

    > li {
      opacity: 0;
    }
  }

  &.dropdown-enter-active {
    max-height: 20rem;
    opacity: 1;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out;

    > li {
      opacity: 1;
    }
  }

  &.dropdown-leave {
    opacity: 1;
    max-height: 20rem;

    > li {
      opacity: 0;
    }
  }

  &.dropdown-leave-active {
    max-height: 0;
    opacity: 0;
    transition: max-height 0.5s ease-out, opacity 0.5s ease-in-out;
  }

  > li {
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, left 0.5s ease-out;
    margin: 2rem 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-of-type(2) {
      transition-delay: 0.24s;
    }

    &:nth-of-type(3) {
      transition-delay: 0.48s;
    }

    &:nth-of-type(4) {
      transition-delay: 0.72s;
    }

    a {
      color: ${({ theme }) => theme.colors.interactiveHighlight};
    }
  }
`

const MobileNavigation = () => {
  const [ dropdownVisible, setDropdownVisible ] = useState(false)
  const [ mobileNavVisible, setMobileNavVisible ] = useState(false)

  const toggleDropdown = e => {
    e.preventDefault()
    setDropdownVisible(!dropdownVisible)
  }

  const toggleMobileNav = lang => e => {
    e.preventDefault()
    const uriPath = getURIPathWithoutLang(lang)
    if (!mobileNavVisible && uriPath.match(/^\/the-project\//) !== null) setDropdownVisible(true)
    setMobileNavVisible(!mobileNavVisible)
  }

  const closeNav = e => {
    setMobileNavVisible(false)
    setDropdownVisible(false)
  }

  const getPath = (lang, path) => {
    return `/${lang}${path}`
  }

  return (
    <LanguageConsumer>
      {({ lang }) => (
        <Container>
          <Query
            render={({ navigation }) => (
              <Fragment>
                <ThemeConsumer>
                  {({ theme }) => (
                    <BurgerMenuLink href='/' onClick={toggleMobileNav(lang)} >
                      <BurgerIcon src={theme.images.burgerMenu} />
                    </BurgerMenuLink>
                  )}
                </ThemeConsumer>
                <ReactCSSTransitionGroup
                  transitionName='mobileNav'
                  transitionEnterTimeout={500}
                  transitionLeaveTimeout={350}
                >
                  { mobileNavVisible &&
                    <Nav key='nav'>
                      <ul>
                        <li><Link onClick={closeNav} to={getPath(lang, '/')} activeClassName='active'>{navigation.labels.home}</Link></li>
                        <li>
                          <a href='#' onClick={toggleDropdown}>
                            <ThemeConsumer>
                              {({ theme }) => (
                                <Fragment>
                                  {navigation.labels.the_project} <span><DropdownArrow src={dropdownVisible ? theme.images.navigationDropDownArrowActive : theme.images.navigationDropDownArrow} alt='Down arrow' /></span>
                                </Fragment>
                              )}
                            </ThemeConsumer>
                          </a>
                          <ReactCSSTransitionGroup
                            transitionName='dropdown'
                            transitionEnterTimeout={500}
                            transitionLeaveTimeout={500}
                          >
                            { dropdownVisible &&
                              <Dropdown>
                                <li><Link onClick={closeNav} to={getPath(lang, '/the-project/overview/')} activeClassName='active'>{navigation.labels.project.overview}</Link></li>
                                <li><Link onClick={closeNav} to={getPath(lang, '/the-project/the-algorithm/')} activeClassName='active'>{navigation.labels.project.the_algorithm}</Link></li>
                                <li><Link onClick={closeNav} to={getPath(lang, '/the-project/the-philosophy/')} activeClassName='active'>{navigation.labels.project.the_philosophy}</Link></li>
                                <li><Link onClick={closeNav} to={getPath(lang, '/the-project/the-sourcecode/')} activeClassName='active'>{navigation.labels.project.the_sourcecode}</Link></li>
                              </Dropdown>
                            }
                          </ReactCSSTransitionGroup>
                        </li>
                        <li><Link onClick={closeNav} to={getPath(lang, '/transparency/')} activeClassName='active'>{navigation.labels.transparency}</Link></li>
                        <li><Link onClick={closeNav} to={getPath(lang, '/updates/')} activeClassName='active'>{navigation.labels.updates}</Link></li>
                        <li><Link onClick={closeNav} to={getPath(lang, '/community/')} activeClassName='active'>{navigation.labels.community}</Link></li>
                        <li><Link onClick={closeNav} to={getPath(lang, '/ada-token/')} activeClassName='active'>{navigation.labels.ada_token}</Link></li>
                      </ul>
                    </Nav>
                  }
                </ReactCSSTransitionGroup>
              </Fragment>
            )}
          />
        </Container>
      )}
    </LanguageConsumer>
  )
}

export default MobileNavigation
