import React, { Fragment } from 'react'
import Select from '../Inputs/Select'
import { LanguageConsumer } from '../../state'
import config from '../../config'
import MediaQuery from 'react-responsive'

function capitalise (s) {
  return `${s[0].toUpperCase()}${s.substring(1)}`
}

export default () => (
  <LanguageConsumer>
    {({ lang, setLang }) => (
      <Fragment>
        <MediaQuery query={`(max-width: ${config.mobileHeaderBreakPoint - 1}px)`}>
          <Select
            value={lang}
            options={Object.keys(config.availableLanguages).map(language => ({ key: language, value: language, label: capitalise(language) }))}
            onChange={lang => setLang(lang)}
            showArrow={false}
          />
        </MediaQuery>
        <MediaQuery query={`(min-width: ${config.mobileHeaderBreakPoint}px)`}>
          <Select
            value={lang}
            options={Object.keys(config.availableLanguages).map(language => ({ key: language, value: language, label: config.availableLanguages[language].label }))}
            onChange={lang => setLang(lang)}
          />
        </MediaQuery>
      </Fragment>
    )}
  </LanguageConsumer>
)
