import cardanoLogo from '../../../resources/images/dark/cardano-logo.svg'
import headerBackground from '../../../resources/images/dark/header-background.svg'
import navigationDropDownArrowActive from '../../../resources/images/dark/navigation-dropdown-arrow-active.svg'
import navigationDropDownArrow from '../../../resources/images/dark/navigation-dropdown-arrow.svg'
import titleDownArrow from '../../../resources/images/dark/title-down-arrow.svg'
import burgerMenu from '../../../resources/images/dark/burger-menu.svg'
import globe from '../../../resources/images/dark/globe.jpg'
import githubMark from '../../../resources/images/dark/github-mark.svg'
import IOHKSymbol from '../../../resources/images/dark/iohk-symbol-inverted.png'
import twitterLogo from '../../../resources/images/dark/twitter-logo.svg'
import facebookLogo from '../../../resources/images/dark/facebook-logo.svg'
import youtubeIcon from '../../../resources/images/dark/youtube-icon.svg'

export default {
  colors: {
    primary: '#121327',
    primaryHighlight: '#36395d',
    secondary: 'rgba(54, 57, 93, 0.95)',
    interactive: '#1fc1c3',
    interactiveHighlight: '#7be8ea',
    text: 'rgba(255, 255, 255, 0.7)',
    accent: 'rgba(255, 255, 255, 0.2)',
    textInverted: 'rgba(0, 0, 0, 0.7)',
    navLine: '#f3f3f4',
    buttonColor: '#121326',
    pageRule: '#36395d'
  },
  dimensions: {
    contentWidth: '124rem',
    contentGutterSize: '2rem'
  },
  images: {
    cardanoLogo,
    headerBackground,
    navigationDropDownArrowActive,
    navigationDropDownArrow,
    titleDownArrow,
    globe,
    githubMark,
    IOHKSymbol,
    twitterLogo,
    facebookLogo,
    youtubeIcon,
    burgerMenu
  }
}
