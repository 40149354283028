import dark from './dark'
import TinyColor from '@ctrl/tinycolor'
import cardanoLogo from '../../../resources/images/light/cardano-logo.svg'
import headerBackground from '../../../resources/images/light/header-background.svg'
import navigationDropDownArrowActive from '../../../resources/images/light/navigation-dropdown-arrow-active.svg'
import navigationDropDownArrow from '../../../resources/images/light/navigation-dropdown-arrow.svg'
import titleDownArrow from '../../../resources/images/light/title-down-arrow.svg'
import globe from '../../../resources/images/light/globe.jpg'
import burgerMenu from '../../../resources/images/light/burger-menu.svg'
import githubMark from '../../../resources/images/light/github-mark.svg'
import IOHKSymbol from '../../../resources/images/light/iohk-symbol.png'
import twitterLogo from '../../../resources/images/light/twitter-logo.svg'
import facebookLogo from '../../../resources/images/light/facebook-logo.svg'
import youtubeIcon from '../../../resources/images/light/youtube-icon.svg'

const colors = {}
Object.keys(dark.colors).map(key => {
  const color = new TinyColor(dark.colors[key])
  color.r = 255 - color.r
  color.g = 255 - color.g
  color.b = 255 - color.b
  colors[key] = color.toRgbString()
})

export default {
  colors: {
    ...colors,
    primary: '#e5e5e5',
    primaryHighlight: '#a5a5a5'
  },
  dimensions: {
    contentWidth: '124rem',
    contentGutterSize: '2rem'
  },
  images: {
    cardanoLogo,
    headerBackground,
    navigationDropDownArrowActive,
    navigationDropDownArrow,
    titleDownArrow,
    globe,
    burgerMenu,
    githubMark,
    IOHKSymbol,
    twitterLogo,
    facebookLogo,
    youtubeIcon
  }
}
