import React from 'react'
import { ThemeConsumer } from '../../state'
import styled from 'styled-components'
import { MdBrightnessMedium } from 'react-icons/md'
import config from '../../config'

const Link = styled.a`
  color: ${({ theme }) => theme.colors.text};
  font-size: 3rem;
  opacity: 0.6;

  @media screen and (max-width: ${config.mobileHeaderBreakPoint - 1}px) {
    font-size: 2rem;
  }
`

const themes = [ 'light', 'dark' ]

const ThemePicker = () => {
  const onClick = (themeString, setTheme) => (e) => {
    e.preventDefault()
    setTheme(themes[themes.indexOf(themeString) ? 0 : 1])
  }

  return (
    <ThemeConsumer>
      {({ themeString, setTheme }) => (
        <Link href='#' onClick={onClick(themeString, setTheme)}>
          <MdBrightnessMedium />
        </Link>
      )}
    </ThemeConsumer>
  )
}

export default ThemePicker
