import TinyColor from '@ctrl/tinycolor'

const makeTransparent = (color, alpha) => {
  const c = new TinyColor(color)
  c.setAlpha(alpha)
  return c.toRgbString()
}

export {
  makeTransparent
}
