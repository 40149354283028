import React, { useState, Fragment, useEffect, createRef } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import { ThemeConsumer, LanguageConsumer } from '../../state'
import Query from './Query'

const Container = styled.div`
  width: 100%;
  position: relative;
  top: 70%;
  text-align: center;
  width: 100%;
  z-index: 1;
`

const Nav = styled.nav`
  display: flex;
  width: 100%;
  margin: 0 auto;

  a {
    color: ${({ theme }) => theme.colors.interactive};
    text-decoration: none;
    text-transform: uppercase;
    flex: 1;
    font-weight: 600;
    font-size: 0.9em;
    letter-spacing: 0.1em;
    transition: color 0.2s ease-in-out;

    &:hover,
    &.active,
    &:focus {
      color: ${({ theme }) => theme.colors.text}
    }
  }

  .navGradients {
    display:flex;
    align-items:baseline;
    justify-content:center;
    width:100%;
    .gradLeft,
    .gradRight {
      opacity:0.7;
      position: relative;
      top:-0.5rem;
      height: 0.1rem;
      min-width:10vw;
    }
    .gradLeft {
      background: linear-gradient(270deg,${({ theme }) => theme.colors.navLine} 0,${({ theme }) => theme.colors.navLine} 65%,hsla(0,0%,100%,0));
    }
    .gradRight {
      background: linear-gradient(90deg,${({ theme }) => theme.colors.navLine} 0,${({ theme }) => theme.colors.navLine} 65%,hsla(0,0%,100%,0));
    }
  }

  > div > ul {
    > li {
      margin: 0;
      display: inline-block;
      padding: 0 1rem;
      position: relative;

      @media (max-width:990px) {
        font-size:80%;
        padding:0 0.8rem;
      }

      &:before {
        left: 0;
        transform: translate(-50%, -50%);
      }

      &:after {
        right: 0;
        transform: translate(50%, -50%);
      }

      &:after,
      &:first-of-type:before,
      &:nth-child(2):before {
        display: block;
        position: absolute;
        content: '';
        width: 0.6rem;
        height: 0.6rem;
        background-color: ${({ theme }) => theme.colors.text};
        opacity: 0.5;
        top: 50%;
        border-radius: 0.3rem;
        z-index: 2;
      }
    }
  }
`

const DropdownArrow = styled.img`
  width: 1.6rem;
  margin: 0;
  vertical-align: sub;
`

const Dropdown = styled.ul`
  position: absolute;
  width: 100%;
  z-index: 1;
  text-align: left;
  min-width: 20rem;
  list-style: none;
  margin: 1.5rem 0 0 0;
  background-color: ${({ theme }) => theme.colors.primaryHighlight};
  padding: 2.6rem;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  border-radius: 0.8rem;
  overflow: hidden;

  > li {
    left: 0;
    position: relative;
    opacity: 1;
    transition: opacity 0.5s ease-in-out, left 0.5s ease-out;

    &:nth-of-type(2) {
      transition-delay: 0.12s;
    }

    &:nth-of-type(3) {
      transition-delay: 0.24s;
    }

    &:nth-of-type(4) {
      transition-delay: 0.36s;
    }
  }

  &.dropdown-enter,
  &.dropdown-leave,
  &.dropdown-enter > li {
    opacity: 0;
  }

  &.dropdown-enter > li {
    left: -100%;
  }

  &.dropdown-enter-active {
    opacity: 1;
  }
`

const DesktopNavigation = () => {
  const dropdownRef = createRef()
  const [ dropdownVisible, setDropdownVisible ] = useState(false)
  const toggleDropdown = e => {
    e.preventDefault()
    setDropdownVisible(!dropdownVisible)
  }

  const getPath = (lang, path) => {
    return `/${lang}${path}`
  }

  const closeDropdown = () => setDropdownVisible(false)

  const documentClickHandler = e => {
    if (!dropdownRef.current) return
    if (!dropdownRef.current.contains(e.target)) closeDropdown()
  }

  useEffect(() => {
    if (!global.document) return
    if (dropdownVisible) {
      global.document.addEventListener('click', documentClickHandler)
    } else {
      global.document.removeEventListener('click', documentClickHandler)
    }

    return () => {
      global.document.removeEventListener('click', documentClickHandler)
    }
  }, [ dropdownVisible ])

  return (
    <LanguageConsumer>
      {({ lang }) => (
        <Container>
          <Query
            render={({ navigation }) => (
              <Nav>
                <div className='navGradients'>
                  <div className='gradLeft' />
                  <ul>
                    <li><Link to={getPath(lang, '/')} activeClassName='active'>{navigation.labels.home}</Link></li>
                    <li>
                      <a href='#' onClick={toggleDropdown}>
                        <ThemeConsumer>
                          {({ theme }) => (
                            <Fragment>
                              {navigation.labels.the_project} <span><DropdownArrow src={dropdownVisible ? theme.images.navigationDropDownArrowActive : theme.images.navigationDropDownArrow} alt='Down arrow' /></span>
                            </Fragment>
                          )}
                        </ThemeConsumer>
                      </a>
                      <ReactCSSTransitionGroup
                        transitionName='dropdown'
                        transitionEnterTimeout={100}
                        transitionLeaveTimeout={500}
                      >
                        {dropdownVisible &&
                          <Dropdown ref={dropdownRef}>
                            <li><Link onClick={closeDropdown} to={getPath(lang, '/the-project/overview/')} activeClassName='active'>{navigation.labels.project.overview}</Link></li>
                            <li><Link onClick={closeDropdown} to={getPath(lang, '/the-project/the-algorithm/')} activeClassName='active'>{navigation.labels.project.the_algorithm}</Link></li>
                            <li><Link onClick={closeDropdown} to={getPath(lang, '/the-project/the-philosophy/')} activeClassName='active'>{navigation.labels.project.the_philosophy}</Link></li>
                            <li><Link onClick={closeDropdown} to={getPath(lang, '/the-project/the-sourcecode/')} activeClassName='active'>{navigation.labels.project.the_sourcecode}</Link></li>
                          </Dropdown>
                        }
                      </ReactCSSTransitionGroup>
                    </li>
                    <li><Link to={getPath(lang, '/transparency/')} activeClassName='active'>{navigation.labels.transparency}</Link></li>
                    <li><Link to={getPath(lang, '/updates/')} activeClassName='active'>{navigation.labels.updates}</Link></li>
                    <li><Link to={getPath(lang, '/community/')} activeClassName='active'>{navigation.labels.community}</Link></li>
                    <li><Link to={getPath(lang, '/ada-token/')} activeClassName='active'>{navigation.labels.ada_token}</Link></li>
                  </ul>
                  <div className='gradRight' />
                </div>
              </Nav>
            )}
          />
        </Container>
      )}
    </LanguageConsumer>
  )
}

export default DesktopNavigation
