import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider, ThemeConsumer, LanguageProvider, PageLoaderProvider } from './state'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import PickerContainer from './components/PickerContainer'
import Header from './components/Header'
import { makeTransparent } from './helpers/color'

const App = ({ element }) => {
  return (
    <ThemeProvider>
      <ThemeConsumer>
        {({ theme }) => (
          <StyledThemeProvider theme={theme} makeTransparent={makeTransparent}>
            <LanguageProvider>
              <PageLoaderProvider>
                <Fragment>
                  <PickerContainer />
                  <Header />
                  {element}
                </Fragment>
              </PageLoaderProvider>
            </LanguageProvider>
          </StyledThemeProvider>
        )}
      </ThemeConsumer>
    </ThemeProvider>
  )
}

App.propTypes = {
  element: PropTypes.node.isRequired
}

export default App
