import React from 'react'
import styled from 'styled-components'
import { ThemeConsumer } from '../../state'

const Container = styled.div`
  text-align: center;
`

const LogoImage = styled.img`
  margin: 0 0 1.6rem 0;
  width: 11rem;
`

const LogoText = styled.p`
  color: ${({ theme }) => theme.colors.text};
  text-shadow: 0 0 1rem ${({ theme }) => theme.colors.text};
  letter-spacing: 0.3rem;
  font-size: 2rem;
  text-transform: uppercase;
`

const Logo = () => (
  <Container className='logo-container'>
    <ThemeConsumer>
      {({ theme }) => (
        <LogoImage src={theme.images.cardanoLogo} alt='Cardano logo' />
      )}
    </ThemeConsumer>
    <LogoText className='logo-text'>Cardano</LogoText>
  </Container>
)

export default Logo
